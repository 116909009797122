/* tab category */
.active-tab {
    background-color: red !important;
    color: white !important;
  }
  

  .markdown-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .markdown-container th, .markdown-container td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  
  .markdown-container th {
    background-color: #f2f2f2;
  }
  